import styles from './Footer.module.scss';
import analytics from '#utils/analytics';

type Props = {
  link: string;
  text: string;
};

const UnsubscribeButton = ({ link, text }: Props) => {
  const handleClick = () => {
    analytics.event({ action: 'Cancel Subscription' });
  };

  return (
    <a className={styles.contactsLink} href={link} onClick={handleClick}>
      {text}
    </a>
  );
};

export default UnsubscribeButton;
